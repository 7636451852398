import { isNodeDebug } from "../../helpers/nodeEnvironment";

export const getSentryEnvironment = (): string => {
  if (isNodeDebug()) {
    return "debug";
  }

  if (process.env.REACT_APP_SENTRY_ENVIRONMENT) {
    return process.env.REACT_APP_SENTRY_ENVIRONMENT;
  }

  return "unknown";
};
