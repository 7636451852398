import { getType } from "../../helpers/getType";

import { getAllowedWorkspacesForSentryLogging } from "./getAllowedWorkspacesForSentryLogging";
import { SentryLogForAllowedWorkspacesParams } from "./sentryLogForAllowedWorkspaces.types";
import { SentryService } from "./SentryService";

export const sentryLogForAllowedWorkspaces = ({
  accountSlug,
  security,
  users,
  teams,
  editable,
  applicationId,
  recordId,
  fieldSlug,
  memberName,
  accessToken,
  env,
  webSocketURL,
  filestackAPIKey,
  platform,
  preset,
}: SentryLogForAllowedWorkspacesParams): void => {
  if (!accountSlug) {
    return;
  }

  const allowedWorkspaces = getAllowedWorkspacesForSentryLogging();

  const isAllowedWorkspace = allowedWorkspaces.includes(accountSlug);

  if (!isAllowedWorkspace) {
    return;
  }

  SentryService.captureException({
    error: new Error("Log: WebView data"),
    data: {
      isSecurityPolicyExist: !!security.policy,
      isSecuritySignatureExist: !!security.signature,
      isAccessTokenExist: !!accessToken,
      isFilestackAPIKeyExist: !!filestackAPIKey,
      usersType: getType(users),
      teamsType: getType(teams),
      editable,
      accountSlug,
      applicationId,
      recordId,
      fieldSlug,
      memberName,
      env,
      webSocketURL,
      platform,
      preset,
    },
    level: "log",
  });
};
