export const getType = (value: unknown): string => {
  try {
    if (value === null) {
      return "null";
    }

    if (Number.isNaN(value)) {
      return "NaN";
    }

    const originalType = typeof value;

    if (originalType !== "object") {
      return originalType;
    }

    if (Array.isArray(value)) {
      return "array";
    }

    const constructorName = Object.prototype.toString.call(value).slice(8, -1).toLowerCase();

    return constructorName;
  } catch {
    return "unknown";
  }
};
