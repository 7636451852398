export const getAllowedWorkspacesForSentryLogging = (): string[] => {
  const defaultAllowedWorkspaces: string[] = [];

  try {
    const allowedWorkspaces = process.env.REACT_APP_ALLOWED_WORKSPACES_FOR_SENTRY_LOGGING;

    if (!allowedWorkspaces) {
      return defaultAllowedWorkspaces;
    }

    const workspaces = allowedWorkspaces.split(",");

    return workspaces;
  } catch {
    return defaultAllowedWorkspaces;
  }
};
