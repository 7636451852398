import * as Sentry from "@sentry/react";

import { getSentryEnvironment } from "./SentryService.utils";

const init = (): void => {
  Sentry.init({
    environment: getSentryEnvironment(),
    dsn: "https://6d7dd362823029ab9f73c74cfbdb6785@o1324044.ingest.us.sentry.io/4508952646778881",
    skipBrowserExtensionCheck: true,
  });
};

const setUser = ({ accountSlug }: { accountSlug: string }): void => {
  Sentry.setUser({
    id: accountSlug,
  });
};

const captureException = ({
  error,
  data,
  level,
}: {
  error: Error;
  data?: unknown;
  level?: Sentry.SeverityLevel;
}): void => {
  const scope = new Sentry.Scope();

  if (data) {
    scope.setExtra("data", data);
  }

  if (level) {
    scope.setLevel(level);
  }

  Sentry.captureException(error, scope);
};

export const SentryService = {
  init,
  setUser,
  captureException,
};
