import React from "react";
import ReactDOM from "react-dom/client";
import { App } from "./App";
import reportWebVitals from "./reportWebVitals";
import { SentryService } from "./services/SentryService/SentryService";

SentryService.init();

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
// Tip: if you need to test the editor on the sandbox
// you need to disable strict mode, otherwise collab will not work.
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
